import classnames from 'classnames'
import { useTranslation } from 'react-i18next';

type Props = {
  status?: boolean
}

export const Ad = ({ status }: Props) => {
  const classes = classnames(
    'bottom-banner',
    {
      'z-9999': status,
    }
  )

  const { t } = useTranslation();
  return (
    <div
      className={classes}
      style={{ backgroundImage: "url('/img/" + t("home.banner_img") + "')" }}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        document.location.href = "https://bit.ly/wordle_unlimited"
       }}
       
      ></div>
  )
  /* return (
    <div className={classes} style={{ backgroundImage: "url('/img/ad_bg.jpg')" }}>
        <img src="/img/ad_appicon.png" alt="jump to download page." className="logo"/>
        <span>Create Your Own Wordle Challenge</span>
        <img src="/img/ad_arrow.png" alt="arrow" className="arrow"/>
        <div className="dl-btn" style={{ backgroundImage: "url('/img/ad_btn.png')"}}>DOWNLOAD</div>
    </div>
  ) */
}
