import { getGuessStatuses } from './statuses'
// import { getShareData, capitalizeFirstLetter } from './param'
// import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
//import { solutionIndex } from './words'


export const ShareStatus = (gameName: string, guesses: string[], text1: string, text3: string) => {
  var share_info = gameName + guesses.length.toString() +
  '/6\n\n' + GenerateEmojiGrid(guesses) + '\n\n' +
  text1 +
  '\nhttps://bit.ly/Wordle_Unlimited\n';
  // + text3;
  copy(share_info);
}

export const GenerateEmojiGrid = (guesses: string[]) => {
  var emojiLines = guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      return guess
        .split('')
        .map((letter, i) => {
          switch (status[i]) {
            case 'correct':
              return '🟩'
            case 'present':
              return '🟨'
            default:
              return '⬜'
          }
        })
        .join('')
    })
  /* while(emojiLines.length < 6){
    emojiLines.push(new Array(guesses[0].length).fill('⬜').join(''))
  } */

  return emojiLines.join('\n')
}
